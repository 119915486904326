import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { FormattedDate } from '@repo/design-system-kit';
import { usePdfPreviewContext } from '../../pdf-preview-context.tsx';
import type { Document, RelatedInvoice, RelatedQuote } from '../../types';
import { ORIGIN, PLACEHOLDER_SECTIONS } from '../../../constants/pdf-preview.ts';
import { Detail, Details } from './details.tsx';

interface DocumentDetailsProps {
  document: Document;
  relatedQuote?: RelatedQuote;
  relatedInvoice?: RelatedInvoice;
}

export function DocumentDetails({
  document,
  relatedInvoice,
  relatedQuote,
}: DocumentDetailsProps): ReactNode {
  const { type } = usePdfPreviewContext();

  if (type === 'credit-note') {
    return <CreditNoteDetails document={document} relatedInvoice={relatedInvoice} />;
  }

  if (type === 'quote') {
    return <QuoteDetails document={document} />;
  }

  if (type === 'self-billing-invoice') {
    return <SelfBillingDetails document={document} relatedInvoice={relatedInvoice} />;
  }

  return <InvoiceDetails document={document} relatedQuote={relatedQuote} />;
}

function CreditNoteDetails({ document, relatedInvoice }: DocumentDetailsProps): ReactNode {
  const { formatMessage } = useIntl();
  const { type, settings, isDraft, origin, displayLanguage } = usePdfPreviewContext();

  const nextNumberFormatted =
    type === 'quote' ? settings?.quoteNextNumberFormatted : settings?.invoiceNextNumberFormatted;

  const displayedNumber = useMemo(() => {
    if (origin === ORIGIN.FORM && settings?.numberingMode === 'automatic') {
      return nextNumberFormatted;
    }

    if (isDraft && origin === ORIGIN.MODAL && settings?.numberingMode === 'automatic') {
      return formatMessage({ id: 'pdf-preview.draft.invoice-number-draft-value' });
    }

    return document.number;
  }, [
    origin,
    isDraft,
    document.number,
    settings?.numberingMode,
    nextNumberFormatted,
    formatMessage,
  ]);

  return (
    <Details>
      <Detail
        data-testid="pdf-preview-number"
        label={formatMessage(
          { id: 'pdf-preview.credit-note.credit-note-number' },
          { language: displayLanguage }
        )}
        value={displayedNumber}
      />

      <Detail
        data-testid="pdf-preview-issue-date"
        label={formatMessage(
          { id: 'pdf-preview.credit-note.invoice-issue-date' },
          { language: displayLanguage }
        )}
        value={<FormattedDate date={document.issueDate} locale={displayLanguage} />}
      />

      {relatedInvoice ? (
        <>
          <Detail
            data-testid="pdf-preview-related-invoice-number"
            label={formatMessage(
              { id: 'pdf-preview.credit-note.related-invoice-number' },
              { language: displayLanguage }
            )}
            value={relatedInvoice.number}
          />

          <Detail
            data-testid="pdf-preview-related-invoice-issue-date"
            label={formatMessage(
              { id: 'pdf-preview.credit-note.invoice-issue-date' },
              { language: displayLanguage }
            )}
            value={<FormattedDate date={relatedInvoice.issueDate} locale={displayLanguage} />}
          />

          {relatedInvoice.purchaseOrder ? (
            <Detail
              data-testid="pdf-preview-related-invoice-purchase-order"
              label={formatMessage(
                { id: 'pdf-preview.purchase-order' },
                { language: displayLanguage }
              )}
              value={relatedInvoice.purchaseOrder}
            />
          ) : null}

          {relatedInvoice.depositInvoice ? (
            <>
              {relatedInvoice.depositInvoice.number ? (
                <Detail
                  data-testid="pdf-preview-related-invoice-deposit-number"
                  label={formatMessage(
                    { id: 'pdf-preview.deposit-invoice-related-number' },
                    { language: displayLanguage }
                  )}
                  value={relatedInvoice.depositInvoice.number}
                />
              ) : null}
              {relatedInvoice.depositInvoice.issueDate ? (
                <Detail
                  data-testid="pdf-preview-related-invoice-deposit-issue-date"
                  label={formatMessage(
                    { id: 'pdf-preview.credit-note.credit-note-issue-date' },
                    { language: displayLanguage }
                  )}
                  value={
                    <FormattedDate
                      date={relatedInvoice.depositInvoice.issueDate}
                      locale={displayLanguage}
                    />
                  }
                />
              ) : null}
              {relatedInvoice.depositInvoice.paidAt ? (
                <Detail
                  data-testid="pdf-preview-related-invoice-deposit-payment-date"
                  label={formatMessage(
                    { id: 'pdf-preview.deposit-payment-date' },
                    { language: displayLanguage }
                  )}
                  value={
                    <FormattedDate
                      date={relatedInvoice.depositInvoice.paidAt}
                      locale={displayLanguage}
                    />
                  }
                />
              ) : null}
            </>
          ) : null}
        </>
      ) : null}
    </Details>
  );
}

function QuoteDetails({ document }: DocumentDetailsProps): ReactNode {
  const { formatMessage } = useIntl();
  const { settings, isDraft, origin, displayLanguage } = usePdfPreviewContext();

  const displayedNumber = useMemo(() => {
    if (origin === ORIGIN.FORM && settings?.numberingMode === 'automatic') {
      return settings.quoteNextNumberFormatted;
    }

    if (isDraft && origin === ORIGIN.MODAL && settings?.numberingMode === 'automatic') {
      return formatMessage({ id: 'pdf-preview.draft.invoice-number-draft-value' });
    }

    return document.number;
  }, [
    origin,
    isDraft,
    document.number,
    settings?.numberingMode,
    settings?.quoteNextNumberFormatted,
    formatMessage,
  ]);

  return (
    <Details>
      <Detail
        data-testid="pdf-preview-number"
        label={formatMessage({ id: 'pdf-preview.quote-number' }, { language: displayLanguage })}
        placeholderSections={[PLACEHOLDER_SECTIONS.NUMBER, PLACEHOLDER_SECTIONS.NUMBER]}
        placeholderWidths={['71px', '65px']}
        value={displayedNumber}
      />

      <Detail
        data-testid="pdf-preview-issue-date"
        label={formatMessage({ id: 'pdf-preview.issue-date' }, { language: displayLanguage })}
        placeholderSections={[
          PLACEHOLDER_SECTIONS.ISSUE_DATE,
          PLACEHOLDER_SECTIONS.ISSUE_DATE_VALUE,
        ]}
        placeholderWidths={['51px', '82px']}
        value={<FormattedDate date={document.issueDate} locale={displayLanguage} />}
      />

      <Detail
        data-testid="pdf-preview-due-date"
        label={formatMessage(
          { id: 'pdf-preview.quote-expiry-date' },
          { language: displayLanguage }
        )}
        placeholderSections={[PLACEHOLDER_SECTIONS.DUE_DATE, PLACEHOLDER_SECTIONS.DUE_DATE_VALUE]}
        placeholderWidths={['43px', '50px']}
        value={<FormattedDate date={document.expiryDate} locale={displayLanguage} />}
      />
    </Details>
  );
}

function SelfBillingDetails({ document, relatedInvoice }: DocumentDetailsProps): ReactNode {
  const { formatMessage } = useIntl();
  const { displayLanguage } = usePdfPreviewContext();

  return (
    <Details>
      <Detail
        data-testid="pdf-preview-number"
        label={formatMessage(
          { id: 'pdf-preview.self-billing.self-invoice.number' },
          { language: displayLanguage }
        )}
        value={document.number}
      />
      <Detail
        data-testid="pdf-preview-issue-date"
        label={formatMessage({ id: 'pdf-preview.issue-date' }, { language: displayLanguage })}
        value={<FormattedDate date={document.issueDate} locale={displayLanguage} />}
      />
      {relatedInvoice?.number ? (
        <Detail
          data-testid="pdf-preview-supplier-invoice-number"
          label={formatMessage(
            { id: 'pdf-preview.self-billing.supplier-invoice.number' },
            { language: displayLanguage }
          )}
          value={relatedInvoice.number}
        />
      ) : null}
      {relatedInvoice?.issueDate ? (
        <Detail
          data-testid="pdf-preview-supplier-invoice-issue-date"
          label={formatMessage({ id: 'pdf-preview.issue-date' }, { language: displayLanguage })}
          value={<FormattedDate date={relatedInvoice.issueDate} locale={displayLanguage} />}
        />
      ) : null}
    </Details>
  );
}

function InvoiceDetails({ document, relatedQuote }: DocumentDetailsProps): ReactNode {
  const { formatMessage } = useIntl();
  const { settings, isDraft, origin, displayLanguage, variant } = usePdfPreviewContext();

  const displayedNumber = useMemo(() => {
    if (origin === ORIGIN.FORM && settings?.numberingMode === 'automatic') {
      return settings.invoiceNextNumberFormatted;
    }

    if (isDraft && origin === ORIGIN.MODAL && settings?.numberingMode === 'automatic') {
      return formatMessage({ id: 'pdf-preview.draft.invoice-number-draft-value' });
    }

    return document.number;
  }, [
    origin,
    isDraft,
    document.number,
    settings?.numberingMode,
    settings?.invoiceNextNumberFormatted,
    formatMessage,
  ]);

  return (
    <Details>
      <Detail
        data-testid="pdf-preview-number"
        label={formatMessage({ id: 'pdf-preview.invoice-number' }, { language: displayLanguage })}
        placeholderSections={[PLACEHOLDER_SECTIONS.NUMBER, PLACEHOLDER_SECTIONS.NUMBER]}
        placeholderWidths={['71px', '65px']}
        value={displayedNumber}
      />

      <Detail
        data-testid="pdf-preview-issue-date"
        label={formatMessage({ id: 'pdf-preview.issue-date' }, { language: displayLanguage })}
        placeholderSections={[
          PLACEHOLDER_SECTIONS.ISSUE_DATE,
          PLACEHOLDER_SECTIONS.ISSUE_DATE_VALUE,
        ]}
        placeholderWidths={['51px', '82px']}
        value={<FormattedDate date={document.issueDate} locale={displayLanguage} />}
      />

      {document.performanceStartDate ? (
        <Detail
          data-testid="pdf-preview-performance-date"
          label={
            document.performanceEndDate
              ? formatMessage(
                  { id: 'pdf-preview.performance-period' },
                  { language: displayLanguage }
                )
              : formatMessage({ id: 'pdf-preview.performance-date' }, { language: displayLanguage })
          }
          placeholderSections={[
            PLACEHOLDER_SECTIONS.PERFORMANCE_DATE,
            PLACEHOLDER_SECTIONS.PERFORMANCE_DATE_VALUE,
          ]}
          placeholderWidths={['51px', '49px']}
          value={
            document.performanceEndDate ? (
              formatMessage(
                { id: 'pdf-preview.performance-period-range' },
                {
                  language: displayLanguage,
                  startDate: (
                    <FormattedDate date={document.performanceStartDate} locale={displayLanguage} />
                  ),
                  endDate: (
                    <FormattedDate date={document.performanceEndDate} locale={displayLanguage} />
                  ),
                }
              )
            ) : (
              <FormattedDate date={document.performanceStartDate} locale={displayLanguage} />
            )
          }
        />
      ) : null}

      {variant !== 'DE' ? (
        <Detail
          data-testid="pdf-preview-due-date"
          label={formatMessage({ id: 'pdf-preview.due-date' }, { language: displayLanguage })}
          placeholderSections={[PLACEHOLDER_SECTIONS.DUE_DATE, PLACEHOLDER_SECTIONS.DUE_DATE_VALUE]}
          placeholderWidths={['43px', '50px']}
          value={<FormattedDate date={document.dueDate} locale={displayLanguage} />}
        />
      ) : null}

      {document.depositInvoiceNumber ? (
        <Detail
          data-testid="pdf-preview-deposit-invoice-number"
          label={formatMessage(
            { id: 'pdf-preview.deposit-invoice-related-number' },
            { language: displayLanguage }
          )}
          value={document.depositInvoiceNumber}
        />
      ) : null}

      {document.depositPaymentDate ? (
        <Detail
          data-testid="pdf-preview-deposit-payment-date"
          label={formatMessage(
            { id: 'pdf-preview.deposit-payment-date' },
            { language: displayLanguage }
          )}
          value={<FormattedDate date={document.depositPaymentDate} locale={displayLanguage} />}
        />
      ) : null}

      {document.purchaseOrder ? (
        <Detail
          data-testid="pdf-preview-purchase-order"
          label={formatMessage({ id: 'pdf-preview.purchase-order' }, { language: displayLanguage })}
          placeholderSections={[
            PLACEHOLDER_SECTIONS.DE_PURCHASE_ORDER,
            PLACEHOLDER_SECTIONS.DE_PURCHASE_ORDER_VALUE,
          ]}
          placeholderWidths={['97px', '53px']}
          value={document.purchaseOrder}
        />
      ) : null}

      {relatedQuote?.number ? (
        <Detail
          data-testid="pdf-preview-quote-number"
          label={formatMessage(
            { id: 'pdf-preview.quote-related-number' },
            { language: displayLanguage }
          )}
          placeholderSections={[
            PLACEHOLDER_SECTIONS.DE_RELATED_QUOTE,
            PLACEHOLDER_SECTIONS.DE_RELATED_QUOTE_VALUE,
          ]}
          placeholderWidths={['97px', '53px']}
          value={relatedQuote.number}
        />
      ) : null}
    </Details>
  );
}
