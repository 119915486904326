import { Button, TextField } from '@repo/design-system-kit';
import { FormattedMessage, useIntl } from 'react-intl';
import cx from 'clsx';
import styles from './verification-code.strict-module.css';

interface VerificationCodeProps {
  errorMessage?: string;
  isInvalid?: boolean;
  onSendSMS: () => void;
  isLoadingSMS?: boolean;
  onOtpChange: (otp: string) => void;
}

export function VerificationCode({
  errorMessage,
  isInvalid,
  onSendSMS,
  isLoadingSMS,
  onOtpChange,
}: VerificationCodeProps): React.ReactNode {
  const { formatMessage } = useIntl();
  return (
    <div className={styles.wrapper}>
      <TextField
        autoComplete="off"
        // eslint-disable-next-line jsx-a11y/no-autofocus -- Autofocus is necessary for OTP input field, the field appear in  popup and we want to drive user attention over it.
        autoFocus
        data-testid="otp-input"
        errorMessage={errorMessage}
        isInvalid={isInvalid}
        label={formatMessage({ id: 'labels.verification-code' })}
        maxLength={6}
        onChange={onOtpChange}
        placeholder={formatMessage({ id: 'placeholders.confirmation_code' })}
        type="text"
      />
      <div className={styles.label}>
        <span className={cx('body-1', styles.smsLabel)}>
          <FormattedMessage id="otp.verification-noreceive" />
        </span>
        <Button isLoading={isLoadingSMS} onPress={onSendSMS} type="button" variant="tertiary">
          <FormattedMessage id="otp.resend" />
        </Button>
      </div>
    </div>
  );
}
