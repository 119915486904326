import type { Column } from '../pdf-preview/types/settings.ts';

/** Default colors applied by BE if not provided in the settings */
export const DEFAULT_COLORS = {
  colorText: '#1d1d1b',
  colorTextAlt: '#ffffff',
  colorTheme: '#1d1d1b',
} as const;

export const PLACEHOLDER_SECTIONS = {
  NUMBER: 'number',
  ISSUE_DATE: 'issueDate',
  ISSUE_DATE_VALUE: 'issueDateValue',
  PERFORMANCE_DATE: 'performanceDate',
  PERFORMANCE_DATE_VALUE: 'performanceDateValue',
  DUE_DATE: 'dueDate',
  DUE_DATE_VALUE: 'dueDateValue',
  CONTACT_ORGANIZATION: 'contactOrganization',
  CONTACT_CUSTOMER: 'contactCustomer',
  ITEMS_TABLE: 'itemsTable',
  TERMS_AND_CONDITIONS: 'termsAndConditions',
  ADDITIONAL_NOTES: 'deCustomMessages',
  FOOTER: 'footer',
  DE_CUSTOMER_VAT_NUMBER: 'deCustomerVatNumber',
  DE_CUSTOMER_VAT_NUMBER_VALUE: 'deCustomerVatNumberValue',
  DE_CUSTOMER_TIN_NUMBER: 'deCustomerTinNumber',
  DE_CUSTOMER_TIN_NUMBER_VALUE: 'deCustomerTinNumberValue',
  DE_PURCHASE_ORDER: 'dePurchaseOrder',
  DE_PURCHASE_ORDER_VALUE: 'dePurchaseOrderValue',
  DE_RELATED_QUOTE: 'deRelatedQuote',
  DE_RELATED_QUOTE_VALUE: 'deRelatedQuoteValue',
  DE_DUE_DATE_DISCLAIMER: 'deDueDateDisclaimer',
  DE_CUSTOM_MESSAGES: 'deCustomMessages',
} as const;

export const CURRENCIES = {
  DEFAULT: 'EUR',
} as const;

export const LOCALE = {
  DEFAULT: 'en',
} as const;

export const STATUS = {
  DRAFT: 'draft',
} as const;

export const INVOICE = {
  MODAL: 'invoice-modal',
  FORM: 'invoice-form',
};

export const ORIGIN = {
  MODAL: 'invoice-modal',
  FORM: 'invoice-form',
  SETTINGS: 'settings-form',
};

export const SELF_BILLING_DOCUMENT_TYPE = {
  TD17: 'TD17',
  TD18: 'TD18',
  TD19: 'TD19',
};

export const DATE_FORMAT = 'DD/MM/YYYY';
export const AUTOMATIC_MODE = 'automatic';

export const DEFAULT_COLUMNS: Column[] = [
  { id: 'description' },
  { id: 'quantity' },
  { id: 'unit_price' },
  { id: 'vat_rate' },
  { id: 'subtotal' },
];
