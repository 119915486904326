import { Button, Popup } from '@repo/design-system-kit';
import { useState } from 'react';
import cx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import { VerifyPhoneNumberSvg } from '../../../svgs/verify-phone-number';
import { faqService } from '../../../utils/faq-composer';
import styles from './mfa.strict-module.css';

interface IntroductionProps {
  onClose?: () => void;
  onGetStarted: () => void;
}

export function Introduction({ onClose, onGetStarted }: IntroductionProps): React.ReactNode {
  // NOTE -- this is needed until we have a better way to handle the portal container in qunit tests
  // https://www.notion.so/qonto/React-popover-library-used-within-react-aria-components-does-mount-popovers-modals-outside-of-qunit--12631ee4c696806e87dbef81e9a27922
  const TEST_PORTAL_CONTAINER = document.getElementById('ember-testing') ?? undefined;
  const [isOpen, setIsOpen] = useState(true);
  const { formatMessage } = useIntl();
  const handleClose = (): void => {
    onClose?.();
    setIsOpen(false);
  };

  return (
    <Popup.Root
      aria-label={formatMessage({ id: 'otp.disclaimer_title' })}
      data-testid="mfa-introduction"
      isOpen={isOpen}
      portalContainer={TEST_PORTAL_CONTAINER}
      role="dialog"
    >
      <Popup.CloseButton onPress={handleClose} />
      <Popup.Header>
        <figure className={styles.illustrationWrap}>
          <VerifyPhoneNumberSvg className={styles.illustration} />
        </figure>
        <Popup.Title>
          <FormattedMessage id="otp.disclaimer_title" />
        </Popup.Title>
        <Popup.Subtitle>
          <FormattedMessage id="otp.disclaimer_desc" />
        </Popup.Subtitle>
      </Popup.Header>
      <Popup.Body>
        <p className={cx('body-2 ', styles.faqLink)}>
          <FormattedMessage
            id="otp.disclaimer_link"
            values={{
              // eslint-disable-next-line react/no-unstable-nested-components -- This is a valid case since enforce the usage of the <a> tag in react-intl
              a: (chunks: React.ReactNode) => (
                <a
                  className="link"
                  href={faqService.getLocalizedArticle(4359650)}
                  rel="noopener"
                  target="_blank"
                >
                  {chunks}
                </a>
              ),
            }}
          />
          &nbsp;
        </p>
      </Popup.Body>
      <Popup.Footer>
        <Button onPress={handleClose} variant="secondary">
          <FormattedMessage id="btn.cancel" />
        </Button>
        <Button data-testid="mfa-introduction-get-started" onPress={onGetStarted} variant="primary">
          <FormattedMessage id="otp.disclaimer_btn" />
        </Button>
      </Popup.Footer>
    </Popup.Root>
  );
}
