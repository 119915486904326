import { Button, LottiePlayer, Popup } from '@repo/design-system-kit';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import generalApproval from '../../../lotties/general-approval.json';
import styles from './mfa.strict-module.css';

interface PhoneNumberProps {
  onClose?: () => void;
  onVerifyNumber: () => void;
  phoneNumber: string;
}

export function PhoneNumber({
  onClose,
  onVerifyNumber,
  phoneNumber,
}: PhoneNumberProps): React.ReactNode {
  // NOTE -- this is needed until we have a better way to handle the portal container in qunit tests
  // https://www.notion.so/qonto/React-popover-library-used-within-react-aria-components-does-mount-popovers-modals-outside-of-qunit--12631ee4c696806e87dbef81e9a27922
  const TEST_PORTAL_CONTAINER = document.getElementById('ember-testing') ?? undefined;
  const [isOpen, setIsOpen] = useState(true);
  const { formatMessage } = useIntl();
  const handleClose = (): void => {
    onClose?.();
    setIsOpen(false);
  };

  return (
    <Popup.Root
      aria-label={formatMessage({ id: 'otp.sms_title' })}
      data-testid="mfa-phone-number"
      isOpen={isOpen}
      portalContainer={TEST_PORTAL_CONTAINER}
      role="dialog"
    >
      <Popup.CloseButton onPress={handleClose} />
      <Popup.Header>
        <figure className={styles.illustrationWrap}>
          <LottiePlayer animationData={generalApproval} className={styles.illustration} />
        </figure>
        <Popup.Title>
          <FormattedMessage id="otp.sms_title" />
        </Popup.Title>
        <Popup.Subtitle>
          <FormattedMessage id="otp.sms_subtitle" />
        </Popup.Subtitle>
      </Popup.Header>
      <Popup.Body>
        <p className="body-1">
          <FormattedMessage id="labels.phone" />
          &nbsp;
          <strong>{phoneNumber}</strong>
        </p>
      </Popup.Body>
      <Popup.Footer>
        <Button onPress={handleClose} variant="secondary">
          <FormattedMessage id="btn.back" />
        </Button>
        <Button data-testid="verify-phone-number" onPress={onVerifyNumber} variant="primary">
          <FormattedMessage id="btn.verify_phone_number" />
        </Button>
      </Popup.Footer>
    </Popup.Root>
  );
}
