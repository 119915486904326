import cx from 'clsx';
import type { ReactElement, ReactNode } from 'react';
import { AnimatePresence, motion } from 'motion/react';
import type { ToggleProps } from '../toggle';
import { Toggle } from '../toggle';
import { Tooltip, type TooltipProps } from '../tooltip';
import styles from './selector-toggle.strict-module.css';

export interface SelectorToggleProps extends ToggleProps {
  icon?: ReactElement;
  title?: string | ReactElement;
  subtitle?: string | ReactElement;
  content?: ReactElement;
  additionalContent?: ReactElement;
  checked?: boolean;
  tooltipPosition?: 'top' | 'bottom';
  tooltipMessage?: string;
  isReadOnly?: boolean;
  isDisabled?: boolean;
}

export interface WithOptionalTooltipProps {
  children: ReactNode;
  tooltipMessage?: TooltipProps['label'];
  tooltipPosition?: TooltipProps['placement'];
}

export function renderOptionalTooltip({
  children,
  tooltipMessage,
  tooltipPosition,
}: WithOptionalTooltipProps): ReactElement {
  return tooltipMessage ? (
    <Tooltip label={tooltipMessage} needsButton placement={tooltipPosition}>
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );
}

export function SelectorToggle({
  isDisabled = false,
  isReadOnly = false,
  className,
  icon,
  title,
  subtitle,
  content,
  additionalContent,
  onChange,
  tooltipMessage,
  tooltipPosition = 'bottom',
  checked,
  ...props
}: SelectorToggleProps): ReactElement {
  return (
    <div
      className={cx(
        styles.wrapper,
        styles.classic,
        tooltipMessage && styles.withTooltip,
        isDisabled && styles.disabled,
        isReadOnly && styles.readonly,
        className
      )}
      data-test-selector-wrapper
    >
      {renderOptionalTooltip({
        tooltipMessage,
        tooltipPosition,
        children: (
          <Toggle
            className={styles.selector}
            isDisabled={isDisabled || isReadOnly}
            isReadOnly={isReadOnly}
            isSelected={checked}
            onChange={onChange}
            {...props}
            data-test-selector
          >
            <div className={styles.contentWrapper}>
              {icon ? (
                <div className={styles.icon} data-test-selector-icon>
                  {icon}
                </div>
              ) : null}
              <div className={styles.header}>
                {title ? (
                  <span className={cx('body-1', styles.title)} data-test-selector-title>
                    {title}
                  </span>
                ) : null}
                {subtitle ? (
                  <span className={cx('body-2', styles.subtitle)} data-test-selector-subtitle>
                    {subtitle}
                  </span>
                ) : null}
              </div>
              {additionalContent ? (
                <div className={styles.additionalContent} data-test-selector-additional-content>
                  {additionalContent}
                </div>
              ) : null}
            </div>
          </Toggle>
        ),
      })}
      <AnimatePresence initial={false}>
        {content && checked ? (
          <motion.div
            animate={{ opacity: 1 }}
            className={styles.content}
            data-test-selector-content
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            transition={{
              opacity: { duration: 0.24, ease: 'easeInOut' },
            }}
          >
            {content}
          </motion.div>
        ) : null}
      </AnimatePresence>
    </div>
  );
}
