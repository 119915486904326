import cx from 'clsx';
import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { PLACEHOLDER_SECTIONS } from '../../../constants/pdf-preview.ts';
import type { ClientDetails, MerchantDetails } from '../../types';
import { usePdfPreviewContext } from '../../pdf-preview-context.tsx';
import { Placeholder } from '../../placeholders';
import { Address } from './address';
import styles from './addresses.strict-module.css';

interface AddressesProps {
  client?: ClientDetails;
  contactEmail?: string;
  merchant: MerchantDetails;
}

export function Addresses({ client, contactEmail, merchant }: AddressesProps): ReactNode {
  const { formatMessage } = useIntl();
  const { displayLanguage, type, variant } = usePdfPreviewContext();

  const deliveryAddressLabel =
    client?.deliveryAddress?.addressName ??
    formatMessage({ id: 'pdf-preview.delivery-address' }, { language: displayLanguage });

  if (variant === 'DE') {
    if (!client) {
      return null;
    }

    return (
      <div className={cx(styles.addresses, styles.DE)}>
        <Address
          address={client.billingAddress}
          label={<h2 className="mb-8 label-2">{client.name}</h2>}
          placeholderSection={PLACEHOLDER_SECTIONS.CONTACT_CUSTOMER}
        />
        {client.deliveryAddress ? (
          <Address
            address={client.deliveryAddress}
            label={<h2 className="body-2 mb-4">{deliveryAddressLabel}</h2>}
          />
        ) : null}
      </div>
    );
  }

  const merchantVatNumber = merchant.vatNumber
    ? `${formatMessage({ id: 'pdf-preview.vat-number' }, { language: displayLanguage })} ${merchant.vatNumber}`
    : undefined;
  const clientVatNumber = client?.vatNumber
    ? `${formatMessage({ id: 'pdf-preview.vat-number' }, { language: displayLanguage })} ${client.vatNumber}`
    : undefined;

  return (
    <div className={styles.addresses}>
      <Address
        address={merchant.address}
        data-testid="pdf-preview-addresses-merchant"
        label={<h2 className="body-1-bold mb-8">{merchant.legalName}</h2>}
        placeholderSection={PLACEHOLDER_SECTIONS.CONTACT_ORGANIZATION}
      >
        {contactEmail ? (
          <Placeholder section={PLACEHOLDER_SECTIONS.CONTACT_ORGANIZATION} width="137px">
            <p className="body-2" data-testid="pdf-preview-addresses-email">
              {contactEmail}
            </p>
          </Placeholder>
        ) : null}
        {merchant.legalNumber ? (
          <Placeholder section={PLACEHOLDER_SECTIONS.CONTACT_ORGANIZATION} width="90px">
            <p className="body-2" data-testid="pdf-preview-addresses-legal-number">
              {merchant.legalNumber}
            </p>
          </Placeholder>
        ) : null}
        {type === 'self-billing-invoice' ? (
          <Placeholder section={PLACEHOLDER_SECTIONS.CONTACT_ORGANIZATION} width="90px">
            <p className="body-2" data-testid="pdf-preview-addresses-vat-number">
              {merchantVatNumber}
            </p>
          </Placeholder>
        ) : null}
      </Address>
      {client ? (
        <Address
          address={client.billingAddress}
          data-testid="pdf-preview-addresses-billing-address"
          label={<h2 className="body-1-bold mb-8">{client.name}</h2>}
          placeholderSection={PLACEHOLDER_SECTIONS.CONTACT_CUSTOMER}
        >
          {client.email ? (
            <Placeholder section={PLACEHOLDER_SECTIONS.CONTACT_CUSTOMER} width="137px">
              <p className="body-2" data-testid="pdf-preview-addresses-email">
                {client.email}
              </p>
            </Placeholder>
          ) : null}
          {client.tinNumber ? (
            <Placeholder section={PLACEHOLDER_SECTIONS.CONTACT_CUSTOMER} width="90px">
              <p className="body-2" data-testid="pdf-preview-addresses-tin-number">
                {client.tinNumber}
              </p>
            </Placeholder>
          ) : null}
          {clientVatNumber ? (
            <Placeholder section={PLACEHOLDER_SECTIONS.CONTACT_CUSTOMER} width="150px">
              <p className="body-2" data-testid="pdf-preview-addresses-vat-number">
                {clientVatNumber}
              </p>
            </Placeholder>
          ) : null}
        </Address>
      ) : null}
      {client?.deliveryAddress ? (
        <Address
          address={client.deliveryAddress}
          data-testid="pdf-preview-addresses-delivery-address"
          label={<h2 className="body-1-bold mb-8">{deliveryAddressLabel}</h2>}
          placeholderSection={PLACEHOLDER_SECTIONS.CONTACT_CUSTOMER}
        />
      ) : null}
    </div>
  );
}
