import { clsx } from 'clsx';
import type { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { usePdfPreviewContext } from '../../pdf-preview-context.tsx';
import { SELF_BILLING_DOCUMENT_TYPE } from '../../../constants/pdf-preview.ts';
import styles from './self-billing-disclaimers.strict-module.css';

interface SelfBillingDisclaimersProps {
  documentType?: string;
}

export function SelfBillingDisclaimers({ documentType }: SelfBillingDisclaimersProps): ReactNode {
  const { formatMessage } = useIntl();
  const { displayLanguage, type } = usePdfPreviewContext();

  const documentTypeNotes = {
    [SELF_BILLING_DOCUMENT_TYPE.TD17]: formatMessage(
      { id: 'pdf-preview.self-billing.document-type.td17' },
      {
        language: displayLanguage,
      }
    ),
    [SELF_BILLING_DOCUMENT_TYPE.TD18]: formatMessage(
      { id: 'pdf-preview.self-billing.document-type.td18' },
      {
        language: displayLanguage,
      }
    ),
    [SELF_BILLING_DOCUMENT_TYPE.TD19]: formatMessage(
      { id: 'pdf-preview.self-billing.document-type.td19' },
      {
        language: displayLanguage,
      }
    ),
  };

  const documentTypeNote = documentType ? documentTypeNotes[documentType] : undefined;

  function emphasize(text: ReactNode): ReactNode {
    return <em>{text}</em>;
  }

  if (type === 'self-billing-invoice') {
    return (
      <div className="mb-8">
        {documentTypeNote ? (
          <p
            className={clsx('body-1', styles.disclaimer)}
            data-testid="pdf-preview-self-billing-document-type"
          >
            {documentTypeNote}
          </p>
        ) : null}
        <p
          className={clsx('body-1', styles.disclaimer)}
          data-testid="pdf-preview-self-billing-informative-note"
        >
          <FormattedMessage
            id="pdf-preview.self-billing.informative-note"
            values={{
              i: emphasize,
              language: displayLanguage,
            }}
          />
        </p>
      </div>
    );
  }

  return null;
}
