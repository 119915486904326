import { createContext, type PropsWithChildren, type ReactNode, useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { STATUS } from '../constants/pdf-preview.ts';
import type {
  Document,
  DocumentType,
  DocumentVariant,
  Origin,
  PlaceholderSection,
  Settings,
} from './types';

interface PdfPreviewContextType {
  isDeposit?: boolean;
  withMockData?: boolean;
  isDraft?: boolean;
  settings?: Settings;
  status?: string;
  currency?: string;
  type: DocumentType;
  origin?: Origin;
  displayLanguage: string;
  variant: DocumentVariant;
  pageCount?: number | string;
  placeholdersOnly?: boolean;
  placeholders?: PlaceholderSection[];
}

const Context = createContext<PdfPreviewContextType | null>(null);

export function usePdfPreviewContext(): PdfPreviewContextType {
  const context = useContext(Context);

  if (!context) {
    throw new Error('usePdfPreviewContext must be used within <PdfPreviewContext />');
  }

  return context;
}

export interface PdfPreviewContextProps {
  type: DocumentType;
  variant: DocumentVariant;
  withMockData?: boolean;
  origin?: Origin;
  userLocale?: string;
  clientLocale?: string;
  document: Pick<Document, 'currency' | 'isDeposit' | 'locale' | 'pageCount' | 'status'>;
  settings?: Settings;
  placeholdersOnly?: boolean;
  activePlaceholderSections?: PlaceholderSection[];
}

export function PdfPreviewContext({
  type,
  variant,
  origin,
  withMockData,
  userLocale,
  clientLocale,
  document,
  placeholdersOnly,
  activePlaceholderSections,
  settings,
  children,
}: PropsWithChildren<PdfPreviewContextProps>): ReactNode {
  const { locale: defaultLocale } = useIntl();

  const isDraft = document.status === STATUS.DRAFT;

  const displayLanguage = useMemo(() => {
    if (withMockData) {
      return userLocale ?? document.locale ?? defaultLocale;
    }

    const locale = document.locale && !clientLocale ? document.locale : clientLocale;

    return locale ?? defaultLocale;
  }, [clientLocale, document.locale, withMockData, defaultLocale, userLocale]);

  const unsupportedVariant =
    (type === 'credit-note' || type === 'self-billing-invoice') && variant === 'DE';

  const value = {
    type,
    // Hack to skip the credit-note DE variant as we don't have a corresponding template on PDFx
    variant: unsupportedVariant ? 'FR' : variant,
    origin,
    settings,
    displayLanguage,
    withMockData,
    isDraft,
    placeholdersOnly,
    placeholders: activePlaceholderSections,
    currency: document.currency,
    isDeposit: document.isDeposit,
    pageCount: document.pageCount,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
