import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { FormattedDate } from '@repo/design-system-kit';
import { PLACEHOLDER_SECTIONS } from '../../../constants/pdf-preview.ts';
import { usePdfPreviewContext } from '../../pdf-preview-context.tsx';
import type { Document } from '../../types';
import { Placeholder } from '../../placeholders';

interface DueDateDisclaimerProps {
  document: Document;
}

const DAY_DURATION = 1000 * 60 * 60 * 24;

export function DueDateDisclaimer({ document }: DueDateDisclaimerProps): ReactNode {
  const { formatMessage } = useIntl();
  const { displayLanguage, withMockData, type, variant } = usePdfPreviewContext();
  const { expiryDate, issueDate, dueDate } = document;

  const dueDateDisclaimer = useMemo(() => {
    const dueDateTimestamp = dueDate ? new Date(dueDate).getTime() : 0;
    const expiryDateTimestamp = expiryDate ? new Date(expiryDate).getTime() : 0;
    const issueDateTimestamp = issueDate ? new Date(issueDate).getTime() : 0;

    if (withMockData) {
      if (type === 'quote') {
        return formatMessage({ id: 'pdf-preview.invoice-example.quote-validated-time' });
      }
      return formatMessage({ id: 'pdf-preview.invoice-example.invoice-payment-time' });
    }

    if (type === 'quote') {
      const differenceIssueEndDate = Math.ceil(
        Math.abs(expiryDateTimestamp - issueDateTimestamp) / DAY_DURATION
      );
      return formatMessage(
        { id: 'pdf-preview.quote-validated-time' },
        {
          number: differenceIssueEndDate,
          language: displayLanguage,
          expiry_date: <FormattedDate date={expiryDate} locale={displayLanguage} />,
          days_amount: differenceIssueEndDate,
        }
      );
    }

    if (type === 'invoice') {
      const differenceIssueEndDate = Math.ceil(
        Math.abs(dueDateTimestamp - issueDateTimestamp) / DAY_DURATION
      );
      return formatMessage(
        { id: 'pdf-preview.invoice-payment-time' },
        {
          number: differenceIssueEndDate,
          language: displayLanguage,
          dueDate: <FormattedDate date={dueDate} locale={displayLanguage} />,
          days_amount: differenceIssueEndDate,
        }
      );
    }

    return '';
  }, [displayLanguage, dueDate, expiryDate, formatMessage, withMockData, issueDate, type]);

  if (variant === 'DE') {
    return (
      <Placeholder section={PLACEHOLDER_SECTIONS.DE_DUE_DATE_DISCLAIMER} width="530px">
        <p className="body-1 mb-8" data-testid="de-invoice-pdf-end-date">
          {dueDateDisclaimer}
        </p>
      </Placeholder>
    );
  }

  return null;
}
